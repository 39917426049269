import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import PageTag from '../components/page-components/PageTag'
import Title from '../components/page-components/Title'
import IntroText from '../components/page-components/IntroText'
import FormContainer from '../components/page-components/contact/FormContainer'
import Form from '../components/page-components/contact/Form'

const Container = styled.div``

const StyledHeader = styled.div`
  width: 100%;
`

const StyledLink = styled.a`
  color: #fff;
`

class Portfolio extends Component {
  render() {
    const { isFirstLoad, timingOffset, ...rest } = this.props
    return (
      <>
        <Helmet>
          <title>Contact Abdus Salam for any project enquiry</title>
          <meta name="description" content="Contact Abdus Salam for any UI UX design or development project" />
        </Helmet>
        <Container>
          <StyledHeader>
            <PageTag delay={timingOffset}>Contact</PageTag>
            <Title delay={timingOffset + 0.3}>
              Want to talk about your project?
            </Title>
            <IntroText delay={timingOffset + 0.6}>
              You can email me at{' '}
              <StyledLink href="mailto:hello@iamabdus.com">
                hello@iamabdus.com
              </StyledLink>{' '}
              or submit this form:
            </IntroText>
          </StyledHeader>
          <FormContainer delay={timingOffset + 1}>
            <Form />
          </FormContainer>
        </Container>
      </>
    )
  }
}

export default Portfolio
