import React, { Component } from 'react'
import styled from 'styled-components'
import * as emailjs from 'emailjs-com'
import { validateEmail } from '../../../utility/common'
import theme from '../../../utility/theme'
import media from '../../../utility/media'
import countryList from '../../../data/country'
import projectTypes from '../../../data/projectTypes'
import budgets from '../../../data/budgets'
import Input from '../../Input'
import Select from '../../Select'
import TextArea from '../../TextArea'

const Form = styled.form``

const StyledDiv = styled.div`
  position: relative;
  z-index: 50;
`

const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${media.lg}) {
    flex-direction: column;
    & > div {
      width: 100%;
      padding: 0;
    }
  }
`

const ButtonSubmit = styled.button`
  width: 100%;
  margin: 0px 0px 100px 0;
  background-color: ${theme.colorGrey};
  padding: 18px 0;
  outline: 0;
  border: 0;
  color: ${({ disabled }) => (disabled ? '#75798c' : '#fff')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  position: relative;
  z-index: 30;
`

const ButtonText = styled.span`
  position: inherit;
  z-index: 10;
`

const ButtonProgress = styled.span`
  width: 0%;
  height: 100%;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: ${theme.colorPrimary};
  transition: width 0.5s ease-out;
`
const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class CustomForm extends Component {
  state = {
    user: {
      name: '',
      email: '',
      country: '',
      countryOption: null,
      referer: '',
      projectType: '',
      projectTypeOption: null,
      budget: '',
      budgetOption: null,
      projectDetails: '',
    },
    submitText: 'Send Message',
    canSubmit: false,
    sendingProgress: 0,
  }

  setSubmitAbility = ({ name, email, projectDetails }) => {
    //Check the state of canSubmit then change it accordingly
    if (
      name.trim().length > 0 &&
      validateEmail(email) &&
      projectDetails.trim().length > 0
    ) {
      this.setState(prevState => ({
        canSubmit: true,
      }))
    } else {
      this.setState(prevState => ({
        canSubmit: false,
      }))
    }
  }

  handleFullName = e => {
    const value = e.target.value
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        name: value,
      },
    }))

    // To set state of canSubmit
    const user = this.state.user

    const userObj = {
      name: value,
      email: user.email,
      projectDetails: user.projectDetails,
    }

    this.setSubmitAbility(userObj)
  }

  handleEmail = e => {
    const value = e.target.value
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        email: value,
      },
    }))

    // To set state of canSubmit
    const user = this.state.user

    const userObj = {
      name: user.name,
      email: value,
      projectDetails: user.projectDetails,
    }

    this.setSubmitAbility(userObj)
  }

  handleCountry = e => {
    const value = e.label

    const countryObj = { label: e.label || '', value: e.value || '' }

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        country: value,
        countryOption: countryObj,
      },
    }))

    // To set state of canSubmit
    const user = this.state.user

    const userObj = {
      name: user.name,
      email: user.email,
      projectDetails: user.projectDetails,
    }

    this.setSubmitAbility(userObj)
  }

  handleReferer = e => {
    const value = e.target.value
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        referer: value,
      },
    }))

    // To set state of canSubmit
    const user = this.state.user

    const userObj = {
      name: user.name,
      email: user.email,
      projectDetails: user.projectDetails,
    }

    this.setSubmitAbility(userObj)
  }

  handleProjectType = e => {
    const value = e.label
    const projectTypeObj = { label: e.label || '', value: e.value || '' }

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        projectType: value,
        projectTypeOption: projectTypeObj,
      },
    }))

    // To set state of canSubmit
    const user = this.state.user

    const userObj = {
      name: user.name,
      email: user.email,
      projectDetails: user.projectDetails,
    }

    this.setSubmitAbility(userObj)
  }

  handleBudget = e => {
    const value = e.value

    const budgetObj = { label: e.label || '', value: e.value || '' }
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        budget: value,
        budgetOption: budgetObj,
      },
    }))

    // To set state of canSubmit
    const user = this.state.user

    const userObj = {
      name: user.name,
      email: user.email,
      projectDetails: user.projectDetails,
    }

    this.setSubmitAbility(userObj)
  }

  handleProjectDetails = e => {
    const value = e.target.value
    this.setState(prevState => {
      return {
        user: {
          ...prevState.user,
          projectDetails: value,
        },
      }
    })

    // To set state of canSubmit
    const user = this.state.user

    const userObj = {
      name: user.name,
      email: user.email,
      projectDetails: value,
    }

    this.setSubmitAbility(userObj)
  }

  sendMail = e => {
    e.preventDefault()
    const {
      name,
      email,
      country,
      referer,
      projectType,
      budget,
      projectDetails,
    } = this.state.user

    if (name.length <= 0 || email.length <= 0 || projectDetails.length <= 0) {
      // Required field need proper value
      return
    }

    this.setState(prevState => {
      return { sendingProgress: 25 }
    })
    const message = `
    <table>
      <tr>
        <th align="left">Name: </th> <td>${name.trim()}</td>
      </tr>
      <tr>
        <th align="left">Email: </th> <td>${email.trim()}</td>
      </tr>
      <tr>
        <th align="left">Country: </th> <td>${country.trim()}</td>
      </tr>
      <tr>
        <th align="left">Referer: </th> <td>${referer.trim()}</td>
      </tr>
      <tr>
        <th align="left">Project Type: </th> <td>${projectType.trim()}</td>
      </tr>
      <tr>
        <th align="left">Budget: </th> <td>${budget.trim()}</td>
      </tr>

      <tr>
        <th align="left">Project Details: </th> <td>${projectDetails.trim()}</td>
      </tr>
    </table>
    `

    const template = {
      reply_to: email,
      from_name: name,
      message_html: message,
    }

    this.setState(prevState => {
      return { sendingProgress: 85 }
    })

    emailjs
      .send(
        'gmail',
        'template_Mkdc8e3M',
        template,
        'user_9ij2WJDK3hTGyw74R8lWu'
      )
      .then(
        response => {
          this.setState(prevState => {
            return {
              user: {
                name: '',
                email: '',
                country: '',
                countryOption: null,
                referer: '',
                projectType: '',
                projectTypeOption: null,
                budget: '',
                budgetOption: null,
                projectDetails: '',
              },
              submitText: 'Holla! Sucessfully Sent',
              canSubmit: false,
              sendingProgress: 100,
            }
          })
        },
        err => {
          this.setState(prevState => {
            return { submitText: 'Failed! Try Again', sendingProgress: 0 }
          })

          console.log('FAILED...Please Try again', err)
        }
      )

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => console.log('Success!'))
      .catch(error => console.log('FAILED...Please Try again', error));
  }

  render() {
    const { isFirstLoad, timingOffset, ...rest } = this.props
    const { user, canSubmit, submitText, sendingProgress } = this.state
    return (
      <Form name="contact"  method="POST" onSubmit={this.sendMail} data-netlify="true">
        <input type="hidden" name="form-name" value="Contact Form" />
        <StyledDiv>
          <InputsContainer>
            <Input
              type={'text'}
              name={'name'}
              value={user.name}
              placeholder={'Your Name'}
              handleChange={this.handleFullName}
            />
            <Input
              type={'email'}
              name={'email'}
              value={user.email}
              placeholder={'Your Email'}
              handleChange={this.handleEmail}
            />
          </InputsContainer>

          <InputsContainer>
            <Select
              name={'country'}
              placeholder="Country"
              options={countryList}
              value={user.countryOption}
              onChange={this.handleCountry}
            />
            <Input
              type={'text'}
              name={'referer'}
              value={user.referer}
              placeholder={'How did you find me?'}
              handleChange={this.handleReferer}
              required={false}
            />
          </InputsContainer>

          <InputsContainer>
            <Select
              name={'projectType'}
              placeholder="Project Type"
              value={user.projectTypeOption}
              options={projectTypes}
              onChange={this.handleProjectType}
            />
            <Select
              name={'budgets'}
              placeholder="Project Budget"
              value={user.budgetOption}
              options={budgets}
              onChange={this.handleBudget}
            />
          </InputsContainer>

          <InputsContainer>
            <TextArea
              name={'projectDetails'}
              value={user.projectDetails}
              placeholder={'Say something about project'}
              handleChange={this.handleProjectDetails}
              width={100}
            />
          </InputsContainer>
        </StyledDiv>
        <InputsContainer>
          <ButtonSubmit type="submit" disabled={!canSubmit}>
            <ButtonText>{submitText}</ButtonText>
            <ButtonProgress style={{ width: sendingProgress + '%' }} />
          </ButtonSubmit>
        </InputsContainer>
      </Form>
    )
  }
}

export default CustomForm
