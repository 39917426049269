import React from 'react'
import styled from 'styled-components'
import theme from '../utility/theme'
import media from '../utility/media'

const InputContainer = styled.div`
  width: ${({ width }) => width + '%'};
  padding: 0;
  margin-bottom: 80px;
  &:nth-child(odd) {
    padding-right: 60px;
  }
  &:nth-child(even) {
    padding-left: 60px;
  }

  @media (max-width: ${media.lg}) {
    margin-bottom: 20px;
    padding: 0 !important;
  }
`

const StyledInput = styled.input`
  width: 100%;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 1.8px;
  text-align: left;
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 5px solid ${theme.colorGrey};
  padding-bottom: 8px;
  ::placeholder {
    color: ${theme.colorSecondary};
    opacity: 1;
  }
  @media (max-width: ${media.xl}) {
    font-size: 24px;
  }
  @media (max-width: ${media.lg}) {
    font-size: 20px;
  }
  @media (max-width: ${media.sm}) {
    font-size: 16px;
  }
`

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.54;
  letter-spacing: 2.8px;
  text-align: left;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  @media (max-width: ${media.lg}) {
    font-size: 12px;
  }
  @media (max-width: ${media.sm}) {
    font-size: 10px;
  }
`

const labelStyle = {
  opacity: 1,
  visibility: 'visible',
}

const Input = props => {
  const {
    name,
    type,
    placeholder,
    value,
    handleChange,
    width = 50,
    required = true,
  } = props
  return (
    <InputContainer width={width}>
      <StyledInput
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        autoComplete="off"
        required={required}
      />
      <StyledLabel htmlFor={name} style={value.length > 0 ? labelStyle : null}>
        {placeholder}
      </StyledLabel>
    </InputContainer>
  )
}

export default Input
