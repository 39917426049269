import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import theme from '../../utility/theme'
import { common } from '../../utility/common'

const StyledText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 2.8px;
  color: ${theme.colorSecondary};
  margin-top: 15px;
`

class IntroText extends Component {
  componentDidMount() {
    this.introTween = TweenMax.from(this.intro, common.duration, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.delay,
    })
  }

  render() {
    return (
      <StyledText innerRef={p => (this.intro = p)}>
        {this.props.children}
      </StyledText>
    )
  }
}

export default IntroText
