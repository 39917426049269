import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import { common, isSmallScreen, isBigScreen } from '../utility/common'
import theme from '../utility/theme'
import media from '../utility/media'

const InputContainer = styled.div`
  width: ${({ width }) => width + '%'};
  padding: 0px;
  margin-bottom: 100px;
  @media (max-width: ${media.lg}) {
    margin-bottom: 25px;
  }
`

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 50px;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 1.8px;
  text-align: left;
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 5px solid ${theme.colorGrey};
  padding-bottom: 8px;
  box-shadow: none;
  overflow: hidden;
  ::placeholder {
    color: ${theme.colorSecondary};
    opacity: 1;
    overflow: hidden;
  }
  @media (max-width: ${media.xl}) {
    font-size: 24px;
  }
  @media (max-width: ${media.lg}) {
    font-size: 20px;
  }
  @media (max-width: ${media.sm}) {
    font-size: 16px;
  }
`

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.54;
  letter-spacing: 2.8px;
  text-align: left;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  @media (max-width: ${media.lg}) {
    font-size: 12px;
  }
  @media (max-width: ${media.sm}) {
    font-size: 10px;
  }
`

const labelStyle = {
  opacity: 1,
  visibility: 'visible',
}

class TextArea extends Component {
  state = {
    currentValue: '',
    hasValue: false,
  }

  changeCurrentState = value => {
    if (value.trim().length <= 0) {
      this.setState({
        hasValue: false,
      })
    } else {
      this.setState({
        hasValue: true,
        currentValue: value,
      })
    }
  }

  componentDidMount() {
    this.changeCurrentState(this.props.value)

    this.textareaTween = TweenMax.from(this.textarea, common.duration, {
      height: isSmallScreen() ? '70px': isBigScreen() ? '120px': '90px',
      ease: common.easing.easeInOut,
      delay: this.props.delay,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.changeCurrentState(this.props.value)
    }

    if (this.state.hasValue) {
      this.textareaTween.reverse()
    } else {
      this.textareaTween.play()
    }
  }

  render() {
    const {
      name,
      placeholder,
      handleChange,
      width = 50,
      required = true,
    } = this.props

    const { hasValue, currentValue } = this.state
    return (
      <InputContainer width={width}>
        <StyledTextArea
          name={name}
          value={hasValue ? currentValue : ''}
          onChange={handleChange}
          placeholder={hasValue ? 'null' : placeholder}
          required={required}
          innerRef={textarea => (this.textarea = textarea)}
        />
        <StyledLabel htmlFor={name} style={hasValue ? labelStyle : null}>
          {placeholder}
        </StyledLabel>
      </InputContainer>
    )
  }
}

export default TextArea
