import React, { Component } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import theme from '../utility/theme'
import media from '../utility/media'

const InputContainer = styled.div`
  width: ${({ width }) => width + '%'};
  padding: 0;
  margin-bottom: 80px;
  &:nth-child(odd) {
    padding-right: 60px;
  }
  &:nth-child(even) {
    padding-left: 60px;
  }
  @media (max-width: ${media.lg}) {
    margin-bottom: 20px;
    padding: 0 !important;
  }
`

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.54;
  letter-spacing: 2.8px;
  text-align: left;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  @media (max-width: ${media.lg}) {
    font-size: 12px;
  }
  @media (max-width: ${media.sm}) {
    font-size: 10px;
  }
`

const labelStyle = {
  opacity: 1,
  visibility: 'visible',
}

const customSelectStyles = {
  control: base => ({
    ...base,
    fontSize: '30px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.18,
    letterSpacing: '1.8px',
    textAlign: 'left',
    background: 'transparent',
    outline: 'none !important',
    border: 'none',
    boxShadow: 'none',
    borderBottom: `5px solid ${theme.colorGrey} !important`,
    borderRadius: 0,
    paddingBottom: '8px',
    '@media (max-width:  1200px)': {
      fontSize: '24px',
    },
    '@media (max-width:  991px)': {
      fontSize: '20px',
    },
    '@media (max-width: 576px )': {
      fontSize: '16px',
    },
  }),

  input: base => ({
    ...base,
    color: '#fff',
  }),

  placeholder: base => ({
    ...base,
    color: theme.colorSecondary,
    opacity: 1,
  }),

  valueContainer: base => ({
    ...base,
    padding: 0,
  }),

  singleValue: base => ({
    ...base,
    color: '#fff',
  }),

  indicatorSeparator: base => ({
    ...base,
    opacity: 0,
  }),

  menu: base => ({
    ...base,
    background: theme.colorGrey,
  }),

  option: (base, state) => ({
    ...base,
    borderBottom: '1px solid ' + theme.colorGrey,
    color: theme.colorSecondary,
    background:
      state.isSelected || state.isFocused ? 'transparent' : theme.colorBg,
    padding: 10,
    ':active': {
      background: 'transparent',
    }
  }),
}

class CustomSelect extends Component {
  state = {
    currentValue: null,
    hasValue: false,
  }

  /**
   * Change current state
   * arg: (currentValue)
   */
  changeCurrentState = valueObj => {
    if (typeof valueObj.label === 'undefined' || valueObj.label === '') {
      this.setState({
        hasValue: false,
      })
    } else {
      this.setState({
        hasValue: true,
        currentValue: valueObj,
      })
    }
  }

  componentDidMount() {
    const valueObj = { ...this.props.value }
    this.changeCurrentState(valueObj)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const valueObj = { ...this.props.value }
      this.changeCurrentState(valueObj)
    }
  }

  render() {
    const {
      name,
      placeholder,
      options,
      width = 50,
      required = false,
      onChange,
    } = this.props
    const { currentValue, hasValue } = this.state

    return (
      <InputContainer width={width} key={name}>
        <Select
          ref={name}
          innerRef={name}
          name={name}
          required={required}
          styles={customSelectStyles}
          options={options}
          placeholder={!hasValue ? placeholder : null}
          value={hasValue ? currentValue : null}
          onChange={onChange}
        />
        <StyledLabel htmlFor={name} style={hasValue ? labelStyle : null}>
          {placeholder}
        </StyledLabel>
      </InputContainer>
    )
  }
}

export default CustomSelect
