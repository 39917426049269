import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import { common } from '../../../utility/common'
import media from '../../../utility/media'

const StyledFormContainer = styled.div`
  margin-top: 100px;
  color: #fff;

  @media (max-width: ${media.md}) {
    margin-top: 50px;
  }
`

class FormContainer extends Component {
  componentDidMount() {
    this.formContainerTween = TweenMax.from(
      this.formContainer,
      common.duration,
      {
        autoAlpha: 0,
        x: -25,
        ease: common.easing.easeOut,
        delay: this.props.delay,
      }
    )
  }

  render() {
    return (
      <StyledFormContainer innerRef={div => (this.formContainer = div)}>
        {this.props.children}
      </StyledFormContainer>
    )
  }
}

export default FormContainer
